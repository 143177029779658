import React from 'react'
import { RESOURCES } from '../../components/constant'
import Layout from '../../components/layout'
import PageStructure from '../../components/pagestructure'
import SEO from '../../components/seo'
const DownloadableCivilList = () => {
  return (
    <div>
      <Layout>
        <SEO title="Downloadable Civil List" />
        <PageStructure
          title="Downloadable Civil List"
          desc="TESTIG"
          mode={RESOURCES}
        />
      </Layout>
    </div>
  )
}

export default DownloadableCivilList
